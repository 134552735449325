import React from "react";
import "./style.css";
import ScrollAnimation from "react-animate-on-scroll";
// import imag from "../../Assets/img/Personalize@2x.webp";
import comunication from "../../Assets/img/pexels-karolina-grabowska-4491469.jpg";
import computer from "../../Assets/img/pexels-andrea-piacquadio-840996.jpg";
import reunion from "../../Assets/img/pexels-jopwell-2422293.jpg";
function Content() {
  return (
    <div className="content_wrapper">
      <div className="content_container">
        <div className="content_boxes">
          <div className="left_area">
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <img src={comunication} alt="grid" />
              </div>
            </ScrollAnimation>
          </div>

          <div className="right_area">
            <ScrollAnimation animateIn="fadeIn" className="fadescroll">
              <div className="title_defualt">COMUNICAÇÃO E SEGMENTAÇÃO</div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> E-mail Marketing
                </div>

                <div className="text">
                  Divulge, venda mais, engage e crie fluxos recuperação de
                  vendas com modelos de e-mails simples de serem personalizados
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Automação de Marketing
                </div>

                <div className="text">
                  Economize tempo e gere mais resultado automatizando envios de
                  e-mails de forma inteligente
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Segmentação
                </div>

                <div className="text">
                  Tenha mais engajamento em suas mensagens criando segmentações
                  para cada público alvo
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <div className="content_container b">
        <div className="content_boxes">
          <div className="left_area">
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <img src={computer} alt="grid" />
              </div>
            </ScrollAnimation>
          </div>

          <div className="right_area">
            <ScrollAnimation animateIn="fadeIn" className="fadescroll">
              <div className="title_defualt">AUMENTE SUAS CONVERSÕES</div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Landing Pages
                </div>

                <div className="text">
                  Crie páginas dedicadas para cada uma de suas campanhas
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Formulários de
                  Inscrição
                </div>

                <div className="text">
                  Aumenta sua base de contatos criando formulários que podem ser
                  integrados facilmente em seu site ou landing page
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="line"></div>

        <div className="content_boxes">
          <div className="left_area">
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <img src={reunion} alt="grid" />
              </div>
            </ScrollAnimation>
          </div>

          <div className="right_area">
            <ScrollAnimation animateIn="fadeIn" className="fadescroll">
              <div className="title_defualt">MENSURE E MELHORE</div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Relatórios de cliques
                  <br />e e-mails abertos
                </div>

                <div className="text">
                  Acompanhe de forma simples o desempenho de cada uma das suas
                  campanhas
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Teste A/B
                </div>

                <div className="text">
                  Aumente o desempenho testando variações dos seus e-mails
                  enviados.
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
              <div className="box">
                <div className="title">
                  <i className="fas fa-border-none"></i> Otimize o horário de
                  envio
                </div>

                <div className="text">
                  Envie um e-mail agora, em um horário e dias definidos ou em um
                  intervalo de tempo relativo e obtenha mais resultados.
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <ScrollAnimation animateIn="fadeIn">
        <div className="button_upper_text">
          Pronto para ter mais resultados e produtividade com o MAV E-mail
          Marketing e Automação?
        </div>
        <div className="cta_wrapper">
          <a
            href="https://web.whatsapp.com/send?phone=553132117760&text=Ol%C3%A1,%20quero%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20m%C3%B3dulo%20LGPD%20da%20MAV,%20preciso%20falar%20com%20o%20setor%20comercial"
            rel="noreferrer"
            target="_blank"
          >
            <button className="button">CLIQUE AQUI E SAIBA COMO ATIVAR.</button>
          </a>
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default Content;
